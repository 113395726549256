














































































import { mapState } from 'vuex';
import * as vr from '@/helpers/validation';
import { ValidationRules } from '@/api/schema';

import Tooltip from '@/components/tooltip/Tooltip.vue';
import PromoCampaignFormMixin from './PromoCampaignFormMixin';

export default {
  name: 'PromoCampaignFormCompany',
  mixins: [PromoCampaignFormMixin],

  components: {
    Tooltip
  },

  computed: {
    ...mapState('Auth', ['tokenSymbol']),
    rules(): ValidationRules {
      return {
        accrualFormatTypeRadioGroup: [vr.required],
        fixedAccrualRewardInput:
          this.enabledFields.accrualFormatType ===
          this.EFieldTypeKey.FIXED_DEPOSIT
            ? this.positiveNumberRules
            : [],
        fixedAccrualLimitReward:
          this.enabledFields.accrualFormatType ===
            this.EFieldTypeKey.FIXED_DEPOSIT &&
          this.formData.fixedAccrualLimitReward
            ? [
                (v) => vr.validNumber(v, 'Value is not valid'),
                vr.positiveNumber
              ]
            : [],
        percentageAccrualDepositInput:
          this.enabledFields.accrualFormatType ===
          this.EFieldTypeKey.PERCENTAGE_DEPOSIT
            ? [
                ...this.positiveNumberRules,
                (v) => vr.lte(v, 100),
                (v) => vr.gte(v, 0.01),
                (v) => vr.maxPrecision(v, 2)
              ]
            : [],
        percentageAccrualLimitReward:
          this.enabledFields.accrualFormatType ===
            this.EFieldTypeKey.PERCENTAGE_DEPOSIT &&
          this.formData.percentageAccrualLimitReward
            ? [
                (v) => vr.validNumber(v, 'Value is not valid'),
                vr.positiveNumber
              ]
            : []
      };
    }
  }
};
